.ant-layout {
  min-height: 100vh;
}

.ant-btn-default {
  color: #2f80ed !important;
}

.ant-btn-link,
.ant-btn-text {
  height: max-content !important;
  padding: 0 !important;
  border: none !important;
  background: none;
}

.ant-btn-text:focus,
.ant-btn-text:hover {
  background: none !important;
}

.ant-form-vertical .ant-form-item:last-child {
  margin-bottom: 0;
}

.ant-typography,
.ant-tabs-nav {
  margin-bottom: 0 !important;
}

.ant-divider.ant-divider-horizontal {
  margin: 0;
}

.ant-picker, .ant-input-number {
  width: 100%;
}

.ant-drawer-title {
  overflow-wrap: anywhere;
}

.ant-image-img {
  object-fit: contain;
}
